/* ------------------------------------ *\
		settings.breakpoints
\* ------------------------------------ */

/**
 * High-level settings and variables,
 * that are accessible in whole project
*/


// Define z-index as map
// This way there will never be confusion
// around what's on top of what.
// A la: https://css-tricks.com/handling-z-index/

/* Example use:

	.header {
	z-index: map-get($zindex, header);
	}
*/
$zindex: (
	ultra                : 900,
	super                : 800,
	overlay              : 700,
	popup 	             : 600,
	header               : 500,
	sky                  : 100,

	mountain             :   50,
	hill                 :   5,
	plain               :    1,
	terra                :   0,
	sub                  :  -1,
);
