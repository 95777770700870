.o-nav__list {
	list-style: none;
	flex-direction: column;
	text-align: center;

	@include mq("md") {
		flex-direction: row;
	}
}

.o-nav__item {
	text-align: center;
}

.o-nav__link {
	color: currentColor;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	font-weight: 700;
	font-size: $font-size--sm;
	display: inline-block;
	text-align: center;
	padding: .75em 0;
	margin: .75em 0;
	border-bottom: 1px solid rgba($nav-color--active, 0);

	&--active {
		color: $nav-color--active;
		border-bottom: 1px solid rgba($nav-color--active, .5);
	}

	&:hover, &:focus {
		color: $nav-color--active;
		border-bottom: 1px solid rgba($nav-color--active, .5);
	}

	@include mq("md") {
		margin: 0;
		font-size: $font-size--xxs;
		padding: 1em 0;
	}
}

.o-nav__link--corporate {
	color: $nav-color--corporate;

	&:hover, &:focus {
		color: rgba($nav-color--corporate, .7);
		border: 0;
	}

	@include transition {
		transition: color .2s 0s cubic-bezier(.77, 0, .175, 1);
	}
}

.o-nav__link--secondary {
	color: rgba($nav-color--active, .5);

	&:hover, &:focus {
		color: rgba($nav-color--active, .5);
	}
}

.o-nav--primary {
	@include mq("lg") {
		width: 70%;
		margin: auto;
	}
}

.o-nav--secondary {

	.o-nav__item {
		@include mq("md") {
			text-align: right;
		}
	}
}