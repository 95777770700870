.o-cookie-disclaimer {
	position: fixed;
	padding: 0 var(--frame-width, 0px) var(--frame-width, 0px) var(--frame-width, 0px);
	z-index: map-get($zindex, overlay);
	color: $cookie-color;
	background-color: $cookie-bg-color;
	width: 100%;
	visibility: hidden;
	bottom: 0;
	transform: translateY(100%);
	transition: 300ms transform $ease-in-out-expo, 0ms visibility 300ms;

	&--visible {
		visibility: visible;
		transform: translateY(0);
		transition: 300ms transform $ease-in-out-expo, 0ms visibility 0ms;
	}
}

.o-cookie-disclaimer__wrapper {
	padding: $spacing--md;
}

.o-cookie-disclaimer__heading {
	margin-bottom: .5em;
}

.o-cookie-disclaimer__content {
	font-weight: $font-weight--normal;
	font-size: $font-size--sm;
	line-height: $line-height--md;
}

.o-cookie-disclaimer__actions {
	margin-top: 1.5em;
}

.o-cookie-disclaimer__accept {
	margin-top: 1em;
	margin-right: 1em;
}

.o-cookie-disclaimer__decline {
	margin-top: 1em;
	margin-right: 1em;
}