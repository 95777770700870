/* ===========================================
organisms.newsletter-popup
========================================= */
.o-newsletter-popup {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: map-get($zindex, popup);

	width: calc(100% - (1rem * 2 ));
	max-width: 600px;

	box-shadow: 0 2px 12px rgba(0, 0, 0, .5);

	visibility: hidden;
	opacity: 0;

	@media only screen and (min-width: 650px) {
		bottom: 20px;
		right: 20px;
	}
}

.o-newsletter-popup__inner {
	position: relative;
	padding: var(--view-width-radical-px, 3rem);

	background-color: $newsletter-popup-background;
	color: $newsletter-popup-color;
	backface-visibility: hidden;
}

/*
/* Newsletter popup: Content
/* -------------------------------------------------- */
.o-newsletter-popup__content {
	position: relative;
	z-index: 1;
}

// Offset close button
.o-newsletter-popup__kicker,
.o-newsletter-popup__heading {
	max-width: calc(100% - 50px);
}

.o-newsletter-popup__kicker {
	@include text-kicker();
	color: $newsletter-popup-kicker;
	margin-bottom: 12px;
}

.o-newsletter-popup__heading {
	line-height: 1.4;

	@media only screen and (max-width: 450px) {
		font-size: 1.2em;
	}
}

/*
/* Newsletter popup: Form
/* -------------------------------------------------- */
.o-newsletter-popup__form {
	--focus-color: #{$newsletter-popup-color};
	// --error-color: orangered;
	--focus-ring: 0 0 2px #{$newsletter-popup-color};
	color: $newsletter-popup-color;
	margin-top: 20px;

	.o-form__field,
	.o-form__control {
		height: 100%;
		width: 100%;
		margin-bottom: 0;
		font-size: 14px;
	}

	.o-form__message {
		margin-top: 9px;
	}

	.a-action {
		max-width: 100%;
	}

	.o-form__field--checkbox {

		.o-form__label::before {
			border: 0;
			background-color: currentColor;
		}

		.o-form__control:checked ~ .o-form__label:after {
			background-color: $color-corporate;
		}
	}

	@media only screen and (max-width: 349px) {

		.o-form__control[type="email"] {
			padding-top: 1.2em;
			padding-bottom: 1.2em;
		}
	}
}

.o-newsletter-popup__grid-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: stretch;
	margin-bottom: 20px;

	&:last-of-type {
		margin-bottom: 0;
	}

	@media only screen and (min-width: 350px) {
		flex-wrap: nowrap;
	}
}

.o-newsletter-popup__grid-item {
	flex: 1 1 auto;

	@media only screen and (max-width: 349px) {

		&:not(:last-of-type) {
			margin-bottom: 10px;
		}
	}
}

.o-newsletter-popup__grid-item--input {

	@media only screen and (min-width: 350px) {
		flex: 0 0 65%;
		padding-right: 20px;
	}
}

/*
/* Newsletter popup: Background
/* -------------------------------------------------- */
.o-newsletter-popup__bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.o-newsletter-popup__bg-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*
/* Newsletter popup: Close button
/* -------------------------------------------------- */
.o-newsletter-popup__close {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2;
	width: 40px;
	height: 40px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, .2);

	@media only screen and (min-width: 450px) {
		top: 21px;
		right: 21px;
	}

	button {
		position: relative;
		height: 14px;
		width: 14px;
		padding: 0;
		cursor: pointer;
		display: inline-block;
		outline: none;
		border: none;
		background: none;
		color: transparent;
		text-indent: 100%;
		white-space: nowrap;
		backface-visibility: hidden;

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #fff;
			backface-visibility: hidden;

			@include transition {
				transition: transform .17s 0s cubic-bezier(.77, 0, .175, 1);
			}
		}

		&::before {
			top: calc(50%);
			transform: rotate(-45deg);
		}

		&::after {
			bottom: calc(50% - 1px);
			transform: rotate(45deg);
		}
	}
}

.o-newsletter-popup__close:hover,
.o-newsletter-popup__close:focus,
.o-newsletter-popup__close:active {

	button::before,
	button::after {
		transform: rotate(0);
	}
}

/*
/* Newsletter popup: States
/* -------------------------------------------------- */
@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeOutDown {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}

// Active (animate in)
.o-newsletter-popup.o-newsletter-popup--active {
	opacity: 1;
	visibility: visible;
	animation: fadeInUp .4s both 0s;
	animation-timing-function: cubic-bezier(.165, .84, .44, 1);
}

// Inactive (animate out)
.o-newsletter-popup.o-newsletter-popup--inactive {
	transition: opacity 0s .4s, visibility 0s .4s;
	animation: fadeOutDown .3s 0s;
	animation-timing-function: cubic-bezier(.165, .84, .44, 1);
}