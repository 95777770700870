/* ===========================================
settings.typography
========================================= */

/**
* High-level settings and variables,
* that are accessible in whole project
*/

// Font families
$font-primary: 'Gotham', sans-serif !default;

// Font weights

// This mapping is the standard mapping between font weights and their names
// Some fonts may vary from this

/*
100    Extra Light or Ultra Light
200    Light or Thin
300    Book or Demi
400    Normal or Regular
500    Medium
600    Semibold, Demibold
700    Bold
800    Black, Extra Bold or Heavy
900    Extra Black, Fat, Poster or Ultra Black
*/

$font-weight--thin:        100;
$font-weight--extralight:  200;
$font-weight--light:       300;
$font-weight--normal:      400;
$font-weight--medium:      500;
$font-weight--bold:        700;
$font-weight--black:       800;
$font-weight--extrablack:  900;



// Font sizes
// Are calculated from our view width radical
// A pattern might be seen, but there is no special system. It's trial and error
// to make the sizes look right in all screen sizes
$font-size--xxs:   calc(11px + .05 * var(--view-width-radical-px, 3rem)); // 1.2rem;
$font-size--xs:    calc(13px + .05 * var(--view-width-radical-px, 3rem)); // 1.4rem;
$font-size--sm:    calc(14px + .05 * var(--view-width-radical-px, 3rem)); // 1.5rem;
$font-size--md:    calc(17px + .05 * var(--view-width-radical-px, 3rem)); // 1.8rem; // This is our base font size - corresponds to the body-text size
$font-size--lg:    calc(16px + .2 * var(--view-width-radical-px, 3rem)); // 2.3rem;
$font-size--xl:    calc(9.5px + .55 * var(--view-width-radical-px, 3rem)); // 3rem;
$font-size--xxl:   calc(10px + .8 * var(--view-width-radical-px, 3rem)); // 4rem;
$font-size--xxxl:  calc(11px + .9 * var(--view-width-radical-px, 3rem)); // 4.6rem;
// $font-size--xxxxl: 6rem;



// Line heights
$line-height--xs:    1;
$line-height--sm:    1.12;
$line-height--md:    1.2; // This is our base line height - corresponds to the body-text line height
$line-height--lg:    1.25;
$line-height--xl:    1.375;
$line-height--xxl:   1.5;
$line-height--xxxl:  1.75;
$line-height--xxxxl: 2;
