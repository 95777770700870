.m-section {
	&__head {
		margin-bottom: $spacing--xs;
	}

	&__kicker {
		font-size: $font-size--xxs;
		font-weight: $font-weight--medium;
		text-transform: uppercase;
		color: $section-accent-color;
		margin-bottom: 1em;
	}

	&__heading {
		margin-bottom: 1em;
	}

	&__lead {
		font-size: $font-size--lg;
		font-weight: $font-weight--light;
		margin-bottom: 2em;
	}

	&--centered {
		.m-section {
			&__head {
				text-align: center;
			}
		}
	}

	&--margined {
		margin-top: #{$spacing--lg};
		margin-bottom: #{$spacing--lg};
	}
}