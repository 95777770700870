/* ===========================================
	 tools.mixins
	 ========================================= */

/**
 * High-level mixins and functions,
 * that are accessible in whole project
 *
 * NOTE:
 * only use mixins, where creating a utility class
 * is not an option.
 * Mixins create code-duplications, don't follow
 * the DRY principle, and therefore are not an efficient
 * way to write code that is applicable to numerous elements.
*/

// Media query mixin
// Min-width is default. For max-width, pass 'max' as second param.

/* Example use:

1. With min-width
	.foo {
			padding: 20px;

			@include mq('sm') {
					padding-top: 40px;
			}
	}

2. With max-width
	.foo {
			padding: 20px;

			@include mq('md', max) {
					padding-top: 10px;
			}
	}
*/
@mixin mq($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			$width: $width - 1px;
		}
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}

@mixin mq-limit($width-start, $width-end, $type-start: min, $type-end: max) {
	@if map_has_key($breakpoints, $width-start) {
		$width-start: map_get($breakpoints, $width-start);
		$width-end: map_get($breakpoints, $width-end);

		@media only screen and (#{$type-start}-width: $width-start) and (#{$type-end}-width: $width-end) {
			@content;
		}
	}
}

// Breaks word when viewport is not wide enough

/* Example use:

1. Standard
	.foo {
		@include break-word();
	}

2. Within breakpoint
	.foo {
		@include mq('sm') {
			@include break-word();
		}
	}
*/
@mixin break-word() {
	word-break: break-all;
	word-break: break-word;
	hyphens: auto;
}

// hide placeholder text for all text inputs
@mixin hide-placeholder() {
	&::-webkit-input-placeholder {
		color: transparent; /* Chrome <=56, Safari < 10 */
	}

	&:-moz-placeholder {
		color: transparent; /* FF 4-18 */
	}

	&::-moz-placeholder {
		color: transparent; /* FF 19-51 */
	}

	&:-ms-input-placeholder {
		text-indent: -1000px; /* IE 10+ */
	}

	&::placeholder {
		color: transparent; /* Modern Browsers */
	}
}

/* Mixin for transitions
	* to be compliant with
	* media-prefers-reduced-motion
	*
*/
@mixin transition {
	@content;
	@media (prefers-reduced-motion: reduce) {
		transition-duration: 0;
	}
}

// Aspect ratio

/* Example use:

	.keep-me-at-aspect-ratio-16-9 {
		@include aspect-ratio(16, 9);
	}

*/

@mixin aspect-ratio($width, $height) {
	position: relative;
	width: 100%;
	display: block;
	overflow: hidden;
	padding-top: #{($height / $width) * 100%};
}

// Text kicker styling
@mixin text-kicker() {
	font-size: $font-size--xxs;
	font-weight: $font-weight--medium;
	text-transform: uppercase;
}