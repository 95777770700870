.o-cta-bar {
	color: $cta-color;
	background-color: $cta-background-color;
	padding: calc(#{$spacing--md} * 2) 0;
	margin-bottom: calc(#{$grid-gutter} * 2);
}

.o-cta-bar__heading {
	margin-bottom: 1em;
	text-align: center;
}

.o-cta-bar__actions {
	text-align: center;
}

.o-cta-bar__signup {
	margin-top: 1em;
	@include mq("md") {
		margin-right: 1em;
	}
	min-width: 18em;
}

.o-cta-bar__call {
	margin-top: 1em;
	min-width: 18em;
}

/*
/* CTA Bar: Media
/* -------------------------------------------------- */
.o-cta-bar--media {
	.o-cta-bar {

		&__container {
			flex-direction: column;
			row-gap: $spacing--lg;
			max-width: 960px;
			margin: 0 auto;

			@include mq("md") {
				flex-direction: row;
			}
		}

		&__heading,
		&__actions {
			@include mq("md") {
				text-align: left;
			}
		}

		&__media {
			max-width: 320px;
		}
	}
}