/* ===========================================
    settings.behavior
   ========================================= */

/**
 * High-level settings and variables,
 * that are accessible in whole project
*/

$trans-time--shorter: .2s;
$trans-time:   		 .3s;
$trans-time--long:  .4s;
$trans-time--longer:  .6s;

$trans-ease: ease-in-out;


// Easing
$ease-dynamic:          cubic-bezier(.86, 0, .07, 1);
$ease-elastic:          cubic-bezier(.77, -.19, .33, 1.12);
$ease-elastic-wild:     cubic-bezier(.55, 1.55, .39, 1.01);
$ease-elastic-subtle:   cubic-bezier(.42, 0, 0, 2.35);
$ease-tender:           cubic-bezier(.73, .09, .21, .96);

// https://easings.net/en#easeInOutExpo
$ease-in-out-expo:      cubic-bezier(1, 0, 0, 1);
$ease-in-out-quint:     cubic-bezier(.86, 0, .07, 1);
