/* ===========================================
	elements.page
	========================================= */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/
:root {
	// Set default values for javascript controlled props
	--view-width-radical: 3rem;
	--view-width-radical-px: 3rem;
	--frame-width: 0px;
	--opacity: 1;
}

html {
	font-family: $font-primary;
	font-size: 10px;
}

body {
	font-size: $font-size--md;
	font-weight: $font-weight--light;
	line-height: $line-height--md;
	color: $body-color;
}

.page {
	will-change: opacity;

	&:before {
		content: "";
		@media all and (-ms-high-contrast:none) {
			content: none;
		}
		width: 100%;
		height: 100%;
		box-shadow: inset 0px 0px 0px var(--frame-width, 0px) white;
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: map-get($zindex, super);

		will-change: box-shadow;
	}
	
	&:after {
		content: "";
		@media all and (-ms-high-contrast:none) {
			content: none;
		}
		width: 100%;
		height: 100%;
		box-shadow: inset 0px 0px 0px 0px transparent;
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		z-index: map-get($zindex, super)+1;
		
		will-change: box-shadow;
		transition: box-shadow 300ms ease-in-out;
	}
}



/* transition active state (same for leave and enter) */
.barba-leave-active, .barba-enter-active {
	transition: opacity 300ms ease-in-out;
}

/* initial state */
.barba-leave {
	opacity: 1;

	& .page {
		&:after {
			box-shadow: inset 0px 0px 0px 0px white;
		}
	}
}

.barba-enter {
	opacity: 0;

	& .page {
		&:after {
			box-shadow: inset 0px 0px 0px 60px white;
		}
	}
}

/* ending state */
.barba-leave-to {
	opacity: 0;

	& .page {
		&:after {
			box-shadow: inset 0px 0px 0px 60px white;
		}
	}
}

.barba-enter-to {
	opacity: 1;

	& .page {
		&:after {
			box-shadow: inset 0px 0px 0px 0px white;
		}
	}
}

/* appear: active state, define the transition */
.barba-once {
	&:after {
		content: "";
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: $color-petrol;
		z-index: map-get($zindex, ultra);
		pointer-events: none;

		will-change: opacity;
	}

	&:before {
		content: "";
		position: fixed;
		width: 5vw;
		height: 2.5px;
		box-shadow: -20px 0px 10px 0px white;
		background: white;
		left: 50%;
		top: 50%;
		z-index: map-get($zindex, ultra)+1;
		transform: translateX(-50%) translateY(-50%);

		will-change: opacity;
	}

	&:before {
		opacity: 0;
		transition: opacity .5s ease-out;
	}
	&:after {
		opacity: 1;
		transition: opacity .5s ease-out;
	}
}

.barba-once-to {
	&:before {
		opacity: 0;
	}
	&:after {
		opacity: 0;
	}
}

.barba-once-active {
	&:before {
		animation-name: bullet, explode;
		animation-timing-function: ease-in, ease-out;
		animation-duration: 500ms, 250ms;
		animation-delay: 1ms, 500ms;

		will-change: transform, width, height, box-shadow, opacity;
	}
}

@keyframes bullet {
	from {
		transform: translateX(-50vw) translateY(-50%);
		width: 1vw;
		box-shadow: 0px 0px 10px 0px white;
		opacity: 1;
	}

	to {
		transform: translateX(-50%) translateY(-50%);
		width: 10vw;
		box-shadow: -20px 0px 10px 0px white;
		opacity: 1;
	}
}

@keyframes explode {
	from {
		transform: translateX(-50%) translateY(-50%);
		width: 1vw;
		height: 2.5px;
		box-shadow: 0px 0px 10px 0px white;
		background: transparent;
		opacity: 1;
	}

	to {
		transform: translateX(-50%) translateY(-50%);
		width: 100vw;
		height: 100vh;
		box-shadow: 0px 0px 10px 10px white;
		background: transparent;
		opacity: 0;
	}
}

// For webkit, but not the new MS Edge (Chromium)
@supports not (-ms-ime-align:auto) {
	@-webkit-keyframes explode {
		from {
			left: 50%;
			top: 50%;

			transform: translateX(-50%) translateY(-50%);
			width: 1vw;
			height: 2.5px;
			box-shadow: 0px 0px 10px 0px white;
			background: transparent;
			opacity: 1;
		}

		to {
			left: 0;
			top: 0;

			transform: translateX(-50%) translateY(-50%);
			width: 100vw;
			height: 100vh;
			box-shadow: 0px 0px 10px 10px white;
			background: transparent;
			opacity: 0;
		}
	}
}