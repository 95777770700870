.a-heading {
	font-weight: $font-weight--bold;
}

// Footer Headings
.a-heading--xs {
	font-size: $font-size--xxs;
}

// Unused
.a-heading--sm {
	font-size: $font-size--md;
}

// Content Card Heading
// Feature Card Heading
// List Card Heading
.a-heading--md {
	font-weight: $font-weight--bold;
	font-size: $font-size--lg;
	line-height: $line-height--xxl;
}

// Section Heading
.a-heading--lg {
	font-weight: $font-weight--bold;
	font-size: $font-size--xl;
	line-height: $line-height--xxl;
}

// Jumbo Card Heading
// Hero Card Heading
.a-heading--xl {
	font-weight: $font-weight--bold;
	font-size: $font-size--xxl;
	line-height: $line-height--xl;
}
