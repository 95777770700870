/* ===========================================
  atoms.action (outlined)
	========================================= */
.a-action {
	cursor: pointer;
	display: inline-block;
	font-weight: $font-weight--bold;
	font-size: $font-size--xxs;
	background-color: transparent;
	border: none;
	padding: 0;
	text-transform: uppercase;
	transition: background-color 300ms $ease-in-out-quint, border-color 300ms $ease-in-out-quint, color 300ms $ease-in-out-quint;

	&--filled,
	&--light,
	&--dark,
	&--transparent {
		padding: 1.5em 2.5em;
		text-decoration: none;
		border: $border--sm solid currentColor;
		width: 100%;
		max-width: 18em;
		text-align: center;
	}

	&--transparent {
		@include button-color($action-color-transparent-primary, $action-color-transparent-secondary);
	}

	&--light {
		@include button-color($action-color-light-primary, $action-color-light-secondary);
	}

	&--dark {
		@include button-color($action-color-dark-primary, $action-color-dark-secondary);
	}
}

@media (prefers-reduced-motion: reduce) {
	.a-action {
		transition: none;
	}
}

.a-action--iconbutton {
	width: 3em;
	height: 3em;
	padding: .5em;
}

.a-action--iconlink {
	display: inline-flex;
	align-items: center;
	font-size: $font-size--md;
	font-weight: $font-weight--medium;
	text-decoration: none;
	column-gap: 1.5rem;

	.a-icon {
		flex-shrink: 0;
		width: 3.5rem;
		transition: all 300ms ease;

		.a-icon__asset {
			max-height: 1em;
		}
	}

	&:hover,
	&:focus {
		.a-icon {
			transform: translateX(0.75rem);
		}
	}
}

.a-action--link {
	color: $link-color;
	text-decoration: underline;
}

.a-action--block {
	display: block;
	width: 70%;
	max-width: 20em;
	margin: 0 auto;
	text-align: center;
}

.a-action--block-v {
	display: block;
	width: 70%;
	max-width: 20em;
	margin: auto;
	text-align: center;
}

/*
/* Action: Filled
/* -------------------------------------------------- */
.a-action.a-action--filled {
	background: $action-color-light-secondary;
	color: $color-white;
	border: 0;
	transition: background-color .3s ease-in-out;

	&:hover,
	&:focus {
		background-color: darken($action-color-light-secondary, 10%);
		color: $color-white;
	}
}