/* ===========================================
  organisms.form
	========================================= */
.o-form {
	color: $form-color;
	--focus-color: #{$form-focus-color};
	--error-color: #{$form-error-color};
	--focus-ring: 0 0 2px #{$form-focus-ring-color};
}

.o-form__section {
	padding: 0;
	margin-bottom: 2em;
	border: none;
}

.o-form__help-block {
	font-size: $font-size--xxs;
	margin-bottom: 1em;
	margin-top: -3em;
}

.o-form__section-title {
	border-bottom: 2px solid $form-color;
	display: block;
	width: 100%;
	padding: 1em 0;

	font-size: $font-size--xxs;
	font-weight: $font-weight--medium;
	text-transform: uppercase;
	margin-bottom: 2em;
}

.o-form__group {
	margin-bottom: 2em;
}

.o-form__field {
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin-bottom: 1em;

	::placeholder {
		// color: $color-slate--lighter;
		color: white;
	}

	&.has-validation-error {
		color: var(--error-color, orangered);

		::placeholder {
			color: var(--error-color, orangered);
			color: white;
		}
	}
}

.o-form__label {
	position: relative;
	display: block;
	width: 100%;
	order: 1;
	cursor: pointer;
}

.o-form__control {
	order: 2;
	background-color: transparent;

	&:focus {
		outline: none;
		box-shadow: var(--focus-ring, 0 0 2px royalblue);
		color: var(--focus-color, royalblue);
	}

	&:focus ~ .o-form__label {
		color: var(--focus-color, royalblue);
	}
}

.o-form__message {
	order: 3;
	margin-top: .5em;
	font-size: .75em;
}

.o-form__field--text {
	.o-form {
		&__control {
			width: 100%;
			border: none;
			border-bottom: $border--sm solid currentColor;
			padding: .5em 0;
		}
	}
}

.o-form__field--textarea {
	.o-form {
		&__control {
			border: $border--sm solid currentColor;
			padding: .5em;
		}
	}
}

.o-form__field--number {
	.o-form {
		&__control {
			width: 100%;
			border: none;
			border-bottom: $border--sm solid currentColor;
			padding: .5em 0;
		}
	}
}

.o-form__field--email {
	.o-form {
		&__control {
			width: 100%;
			border: none;
			border-bottom: $border--sm solid currentColor;
			padding: .5em 0;
		}
	}
}

.o-form__field--checkbox {
	.o-form {
		&__control {
			width: 1.3em;
			height: 1.3em;
			left: 0;
			top: 0;
			position: absolute;
			opacity: 0;
		}

		&__label {
			padding-left: 2em;
			line-height: 1.3em;
		}

		&__label:before {
			content: "";
			display: inline-block;
			border: $border--sm solid currentColor;
			width: 1.3em;
			height: 1.3em;
			left: 0;
			position: absolute;
		}

		&__label:after {
			content: "";
			display: inline-block;
			background-color: transparent;
			width: .6em;
			height: .6em;
			position: absolute;
			left: 0;
			top: 0;
			line-height: .75em;
			transform: translate(.35em, .35em);
		}

		&__control:checked ~ .o-form__label:after {
			background-color: currentColor;
		}

		&__control:focus ~ .o-form__label {
			outline: none;
			box-shadow: var(--focus-ring, 0 0 2px royalblue);
		}
	}
}

.o-form__field--radio {
	.o-form {
		&__control {
			width: 1.3em;
			height: 1.3em;
			left: 0;
			top: 0;
			position: absolute;
			opacity: 0;
		}

		&__label {
			padding-left: 2em;
			line-height: 1.3em;
		}

		&__label:before {
			content: "";
			display: inline-block;
			border: $border--sm solid currentColor;
			border-radius: 100%;
			width: 1.3em;
			height: 1.3em;
			left: 0;
			position: absolute;
		}

		&__label:after {
			content: "";
			display: inline-block;
			background-color: transparent;
			border-radius: 100%;
			width: .6em;
			height: .6em;
			position: absolute;
			left: 0;
			top: 0;
			line-height: .75em;
			transform: translate(.35em, .35em);
		}

		&__control:checked ~ .o-form__label:after {
			background-color: currentColor;
		}

		&__control:focus ~ .o-form__label {
			outline: none;
			box-shadow: var(--focus-ring, 0 0 2px royalblue);
		}
	}
}

.o-form__field--select {
	.o-form {
		&__control {
			width: 100%;
			border: none;
			border-bottom: $border--sm solid currentColor;
			padding: .5em 0;
		}
	}
}

.o-form--compact {
	.o-form__field--text,
	.o-form__field--textarea,
	.o-form__field--number,
	.o-form__field--email {
		.o-form__label {
			// display: none;
			color: $color-slate--lighter;
			position: absolute;
			top: 0.5em;
			transition: all 0.2s ease-out;
			transform-origin: left top;
		}
		.o-form__control:focus ~ .o-form__label {
			transform: scale(0.7) translateY(-2em);
		}
	}
	.o-form__field--notEmpty {
		.o-form__label {
			transform: scale(0.7) translateY(-2em);
		}
	}
}

/*
	/* Form: Filled
	/* -------------------------------------------------- */
.o-form__control.o-form__control--filled {
	border: 0;
	padding: .75em 1.25em;
	background-color: $form-filled-background;
	color: $form-filled-color;
}
