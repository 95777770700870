// Global spacing units

// $spacing--base: 3rem;
$spacing--base: var(--view-width-radical-px, 3rem);

$spacing--xxs:  calc(#{$spacing--base} / 4);
$spacing--xs:   calc(#{$spacing--base} / 2);
$spacing--sm:   calc(#{$spacing--base} / 1.5);
$spacing--md:   #{$spacing--base};
$spacing--lg:   calc(#{$spacing--base} * 1.5);
$spacing--xl:   calc(#{$spacing--base} * 2);
$spacing--xxl:  calc(#{$spacing--base} * 4);
