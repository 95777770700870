/* ===========================================
  atom.media
  ========================================= */
.a-media {
	width: 100%;
	display: block;
	&__asset {
		width: 100%;
		height: auto;
		display: block;
	}
}

/*
/* Media: Cover
/* -------------------------------------------------- */
.a-media--cover .a-media__asset {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*
/* Media: Maintain 16/9 format
/* -------------------------------------------------- */
.a-media--16-9 {
	position: relative;

	&::after {
		content: '';
		@include aspect-ratio(16, 9);
	}

	> video {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}