.m-logo {
	color: inherit;
	display: block;
	text-decoration: none;
}

.m-logo__gfx {
	display: block;
	width: 1em;
	height: 1em;
}