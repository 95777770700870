.m-article-text {
	line-height: $line-height--xxxl;
	font-size: $font-size--md;

	p {
		margin-bottom: 2em;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: $font-weight--bold;
		margin-top: 1em;
	}

	blockquote {
		margin-top: 2em;
		margin-bottom: 2em;
		margin-left: auto;
		margin-right: auto;
		font-size: $font-size--lg;
		font-weight: $font-weight--bold;
		line-height: $line-height--xxxl;
		color: $article-accent-color;
		text-align: center;

		&:before {
			content: "";
			display: block;
			width: 3em;
			height: 3em;
			margin: auto;
			background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='rgb(#{red($article-accent-color)}, #{green($article-accent-color)}, #{blue($article-accent-color)})' stroke='none' viewBox='0 0 112 112'><path d='M28.5,34.1927V69.80314h7.0615V77.8073l8.004-8.00416H83.5V34.1927ZM82.167,68.47014H43.01334L36.8945,74.589V68.47014H29.833V35.5257H82.167Z'/><circle cx='47.52629' cy='52.30688' r='1.9772'/><circle cx='56.00001' cy='52.30688' r='1.9772'/><circle cx='64.47374' cy='52.30688' r='1.9772'/></svg>");
			background-size: contain;
			background-repeat: no-repeat;
		}

		&:after {
			content: "";
			display: block;
			width: 3em;
			height: 0;
			margin: .5em auto .5em auto;
			border-bottom: 1px solid $article-accent-color;
		}
	}

	table {
		border-spacing: 5vw 0;
		margin-left: -5vw;
		margin-right: -5vw;
		margin-top: 1em;
	}

	th {
		text-align: left;
		font-weight: $font-weight--normal;
		padding-top: .75em;
	}
	th, td {
		vertical-align: top;
	}
	td {
		font-size: $font-size--sm;
		color: $article-cell-color;
	}

	ul, ol {
		margin-bottom: 2em;
		padding-left: 1em;
	}
}