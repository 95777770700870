@mixin button-color($primary-color, $secondary-color) {
	background-color: transparent;
	color: $primary-color;
	border-color: $primary-color;

	&:hover,
	&:focus {
		background-color: $primary-color;
		color: $secondary-color;
	}
}