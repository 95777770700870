/* ===========================================
	utilities.container
	========================================= */

/**
 *  Utilities – utility- and helper classes
 *  with the ability to override anything
*/

.u-container {
	max-width: calc(1248px + (2 * var(--view-width-radical-px)));
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--view-width-radical-px, 3rem);
}

.u-container--narrow {
	max-width: calc(960px + (2 * var(--view-width-radical-px)));
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--view-width-radical-px, 3rem);
}

.u-container--slim {
	max-width: calc(720px + (2 * var(--view-width-radical-px)));
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--view-width-radical-px, 3rem);
}

.u-container--skinny {
	max-width: calc(640px + (2 * var(--view-width-radical-px)));
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--view-width-radical-px, 3rem);
}

.u-container--fluid {
	margin-left: auto;
	margin-right: auto;
	padding: 0 var(--view-width-radical-px, 3rem);
}