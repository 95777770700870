/* ===========================================
  atom.image
  ========================================= */
.m-card {
	position: relative;
	height: 100%;

	&__kicker {
		font-size: $font-size--xxs;
		font-weight: $font-weight--medium;
		text-transform: uppercase;
	}

	&__link {
		display: block;
		color: inherit;
		text-decoration: inherit;
	}
}

/*
/* Card: Content
/* -------------------------------------------------- */
.m-card--content {
	.m-card {
		&__mediabox {
			width: 100%;
			height: auto;
			color: $color-corporate;
			margin: 2rem auto;
		}

		&__textbox {
			font-size: $font-size--sm;
			line-height: $line-height--xxl;

			& dt {
				color: $card-color-accent;
				font-size: $font-size--xxs;
				font-weight: $font-weight--medium;
				text-transform: uppercase;
				margin-bottom: 0.5em;
			}

			& dd {
				margin-bottom: 1.5em;
			}

			& time {
				font-size: $font-size--lg;
				font-weight: $font-weight--medium;
			}

			a {
				color: currentColor;
				text-decoration: none;
			}
		}

		&__action {
			margin-top: 2em;
		}
	}
}

/*
/* Card: Blurb
/* -------------------------------------------------- */
.m-card--blurb {
	.m-card {
		&__heading {
			line-height: $line-height--lg;
		}

		&__textbox {
			font-size: $font-size--md;
			line-height: $line-height--xxl;
		}

		&__kicker {
			color: $card-color-accent;
			display: inline-block;
			margin-bottom: 1em;
		}

		&__mediabox {
			display: block;
			margin-bottom: 0.75em;
		}

		&__status {
			display: inline-block;
			font-size: $font-size--xxs;
			font-style: italic;

			&:before {
				content: "\2014\2002"; //em dash and en space
			}
		}

		&__text {
			font-size: $font-size--sm;
			color: $card-color-text;

			a {
				color: currentColor;
			}
		}

		&__date {
			font-size: $font-size--sm;
			margin-top: 0.5em;
		}

		&__datetext {
			&:after {
				content: ",";
			}
		}

		&__action {
			margin-top: 3em;
		}
	}

	.m-card__date + .m-card__text:before {
		content: "";
		display: block;
		height: 0;
		width: 5em;
		border-bottom: $border--sm solid currentColor;
		margin-top: 1.5em;
		margin-bottom: 1.5em;
	}
}

/*
/* Card: Teaser
/* -------------------------------------------------- */
.m-card--teaser {
	text-align: center;

	.m-card {
		&__mediabox {
			width: 9rem;
			height: 9rem;
			margin: 1em auto;
		}

		&__textbox {
			font-size: $font-size--sm;
		}

		&__heading-container {
			position: relative;
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
		}

		&__heading {
			font-size: $font-size--md;
			font-weight: 300;
		}

		&__heading-container {
			position: relative;
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
		}

		&__heading {
			font-size: $font-size--md;
			font-weight: 300;
		}

		&__heading-icon {
			width: 14px;
			height: 9px;
			margin-left: 10px;
			margin-top: 2px;
		}

		&__text {
			margin-top: 1em;
		}
	}
}

/*
/* Card: Feature
/* -------------------------------------------------- */
.m-card--feature {
	background: $card-color-background;
	text-align: left;
	width: 100%;

	@include mq("md") {
		overflow: hidden;
	}

	.m-card {
		&__textbox {
			margin-top: 1em;
			margin-bottom: 1em;

			@include mq("md") {
				margin-bottom: 0;
			}
		}

		&__mediabox {
			display: block;
			height: 100%;
			padding-bottom: 70%;
			position: relative;
			&.a-image--cover {
				.a-image__asset {
					position: absolute;
				}
			}
			&--video {
				display: block;
				height: 100%;
				position: relative;
				&.a-image--cover {
					.a-image__asset {
						position: absolute;
					}
				}
			}
		}

		&__kicker {
			position: absolute;
			z-index: map-get($zindex, mountain);
			top: $spacing--md;
			left: $spacing--md;
			text-align: left;
			line-height: $line-height--xxl;
			color: $card-color-kicker;
		}

		&__action:not(:first-child) {
			margin-top: 3em;
		}

		&__heading {
			margin-bottom: 0.5em;
		}
	}

	&.m-card {
		&--rowspan-2 {
			.m-card__mediabox {
				padding-bottom: calc(140% + calc(#{$grid-gutter} * 2));
			}
		}
	}

	@include mq("md") {
		.m-card {
			&__textbox {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				position: absolute;
				display: flex;
				justify-content: flex-start;
				padding: calc(#{$spacing--lg} * 1.25) $spacing--md $spacing--md
					$spacing--md;
				flex-direction: column;
				transform: translateX(-101%);
				transition: transform 300ms $ease-in-out-expo 0.3s;
				color: $card-color-foreground;
				margin-top: 0;
				z-index: map-get($zindex, terra);
				font-size: $font-size--sm;
				overflow: hidden;
				background-color: $card-color-background-dark;

				&:after {
					content: "";
					display: block;
					width: 1.5em;
					height: 1.5em;
					margin: auto;
					background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='rgb(#{red($article-accent-color)}, #{green($article-accent-color)}, #{blue($article-accent-color)})' stroke='none' viewBox='0 0 512 512'><polygon points='374.81 140.8 358.36 160.4 456.95 243.19 0 243.19 0 268.79 456.96 268.79 358.35 351.6 374.82 371.2 512 255.99 374.81 140.8'/></svg>");
					background-size: contain;
					background-repeat: no-repeat;
					position: absolute;
					bottom: $spacing--sm;
					right: $spacing--md;
					transform: translateX(300%);
					transition: transform 750ms $ease-in-out-expo 0.5s;
				}
			}
			&__mediabox,
			&__mediabox--video {
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					transform: translateX(-101%);
					transition: transform 300ms $ease-in-out-expo;
					background-color: $card-color-background-dark;
				}
			}

			&__kicker {
				transition: color 300ms $ease-in-out-expo;
			}

			&__heading {
				font-size: $font-size--lg;
			}
		}

		&:hover .m-card__textbox,
		&:focus .m-card__textbox {
			transform: translateX(0);

			&:after {
				transform: translateX(0);
			}
		}

		&:hover .m-card__mediabox,
		&:focus .m-card__mediabox {
			transform: translateX(0);

			&:after {
				transform: translateX(0);
			}
		}
		&:hover .m-card__mediabox--video,
		&:focus .m-card__mediabox--video {
			transform: translateX(0);

			&:after {
				transform: translateX(0);
			}
		}

		&:hover .m-card__kicker,
		&:focus .m-card__kicker {
			color: $card-color-accent;
		}
	}
}

/*
/* Card: Hero
/* -------------------------------------------------- */

.m-card--hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	overflow: hidden;
	margin-bottom: calc(#{$grid-gutter} * 2);
	padding: calc(#{$grid-gutter} * 3) 0;

	@include mq("md") {
		min-height: 600px;
		padding: calc(#{$grid-gutter} * 6) 0;
	}

	.m-card {
		&__overlay {
			position: absolute;
			top: -50%;
			left: 25%;
			right: 0;
			bottom: 0;
			background-color: rgba($color-white, 0.3);
			clip-path: polygon(100% 0, 0% 100%, 100% 100%);
			animation: overlaySlideIn 0.5s ease-out;
		}

		&__textbox {
			position: relative;
			padding: $spacing--md $spacing--md;
			text-align: center;
		}

		&__heading,
		&__text,
		&__action {
			animation: fadeInUp 0.5s ease-in 0.25s forwards;
			opacity: 0;
		}

		&__heading {
			@include mq(md) {
				font-size: 6rem;
				line-height: 8rem;
			}

			@include mq(xl) {
				font-size: 8rem;
				line-height: 9.6rem;
			}
		}

		&__text {
			margin-top: $spacing--lg;
			animation-delay: 0.5s;
		}

		&__action {
			margin-top: $spacing--md;
			animation-delay: 0.75s;

			&.a-action--iconlink {
				color: inherit;
			}
		}

		&__mediabox {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&__mediabox.a-icon {
			padding: calc(#{$spacing--lg} * 2);
		}
	}
}

.m-card--hero--big {
	min-height: 100vh;

	@include mq("xxl") {
		min-height: 900px;
	}

	.m-card {
		&__overlay {
			position: absolute;
			top: -30%;
			left: auto;
			right: 0;
			bottom: 0;
			width: 80%;
			background-color: $color-neon;
			animation: overlaySlideIn 0.5s ease-out;

			@include mq(md) {
				width: 60%;
			}
		}

		&__mediabox {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba($color-black, 0.3);
				pointer-events: none;
			}
		}

		&__textbox {
			position: relative;
			max-width: calc(700px + #{$spacing--xxl});
			margin-right: 0;
			padding: $spacing--lg $spacing--lg;
			text-align: right;

			@include mq(md) {
				margin-right: 8%;
				padding-left: $spacing--xxl;
				padding-right: 0;
			}
		}

		&__heading {
			@include mq(xxl) {
				font-size: 12rem;
				line-height: 14.4rem;
			}
		}

		&__text {
			max-width: 400px;
			margin-left: auto;
			font-size: $font-size--lg;
			line-height: $line-height--xl;
			font-weight: $font-weight--medium;
		}

		&__action {
			margin-left: auto;
		}
	}
}

/*
/* Card: Jumbo
/* -------------------------------------------------- */
.m-card--jumbo {
	height: 100%;
	max-width: 100%;
	min-height: 400px;
	background: $card-color-background-dark;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: calc(#{$grid-gutter} * 2);
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		// min-height: 285px;
		margin-top: -90px;
	}
	.m-card {
		&__textbox {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: calc(#{$spacing--lg} * 2);
			max-width: 1250px;
			margin: auto;
			opacity: var(--opacity);
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				// position: relative;
				padding-top: 0;
				width: 100%;
			}
		}

		&__mediabox {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			color: $card-color-accent;
		}

		&__mediabox.a-icon {
			padding: calc(#{$spacing--lg} * 2);
		}

		&__kicker {
			position: relative;
			top: unset;
			left: unset;
			color: $card-color-foreground-dimmed;
			font-weight: $font-weight--medium;
			margin-bottom: 1em;
		}
	}
}

/*
/* Card: Highlight
/* -------------------------------------------------- */
.m-card--highlight {
	height: 100%;
	min-height: 600px;
	background: $card-color-background-dark;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	.m-card {
		&__textbox {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: $spacing--xl;
			max-width: calc(720px + #{$spacing--xl} + #{$spacing--xl});
			margin: auto;
		}

		&__mediabox {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			color: $card-color-accent;
		}

		&__kicker {
			font-weight: $font-weight--medium;
			margin-bottom: 1em;
		}

		&__heading {
			margin-top: 0.5em;
			margin-bottom: 0.5em;
		}

		&__action {
			margin-top: 5em;
		}
	}
}

/*
/* Card: Quote
/* -------------------------------------------------- */
.m-card--quote {
	position: relative;
	margin: 6rem auto;

	.m-card {

		&__box {
			padding: $spacing--xl;
			background-color: $color-neon-light;
			color: $color-neon;
		}

		&__textbox {
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			max-width: calc(600px + 2 * #{$spacing--xl});
			margin: auto;
		}

		&__text {

			blockquote {
				font-size: $font-size--lg;
				line-height: $line-height--xxxxl;
				font-weight: $font-weight--bold;
			}

			cite {
				display: block;
				margin-top: $spacing--md;
				font-size: $font-size--xs;
				line-height: $line-height--md;
				font-style: normal;
				font-weight: $font-weight--normal;
			}
		}
	}
}

/*
/* Card: Error
/* -------------------------------------------------- */
.m-card--error {
	height: 100%;
	min-height: 600px;
	text-align: center;
	background-color: transparent;

	.m-card {
		&__kicker {
			max-width: 850px;
			color: $card-color-accent;
			z-index: map-get($zindex, mountain);
		}

		&__textbox {
			align-items: center;
			padding: $spacing--xl;
			max-width: 850px;
			margin: auto;
		}

		&__heading {
			font-size: calc(var(--view-width-radical-px) * 8); // 300px;
			color: $color-grey--10;
			position: absolute;
			z-index: map-get($zindex, hill);
		}

		&__text {
			font-size: $font-size--xxxl;
			font-weight: $font-weight--medium;
			z-index: map-get($zindex, mountain);
		}
	}
}

/*
/* Card: Centered
/* -------------------------------------------------- */
.m-card--centered {
	text-align: center;
}

/*
/* Card: Padded
/* -------------------------------------------------- */
.m-card--padded {
	padding: $spacing--md;
}

.m-card--padded-x2 {
	padding: $spacing--xl;
}

/*
/* Card: Negative
/* -------------------------------------------------- */
.m-card--negative {
	color: $card-color-foreground;
	background-color: $card-color-background-dark;

	.m-card__mediabox {
		color: $card-color-accent-background-dark;
	}

	&.m-card--feature {
		background-color: transparent;

		.m-card__kicker {
			color: currentColor;
		}

		.m-card__textbox {
			color: $card-color-text-dark;
		}

		@include mq("md") {
			&:hover .m-card__kicker,
			&:focus .m-card__kicker {
				color: $card-color-accent;
			}
		}

		@include mq("md") {
			.m-card__textbox {
				color: $card-color-foreground;
				background-color: $card-color-background-dark;
			}
		}
	}
}

/*
/* Card: Bordered
/* -------------------------------------------------- */
.m-card--bordered {
	border: $border--sm solid $color-grey--10;
}
