.h-header {
	position: sticky;
	top: 0;
	font-size: $font-size--xxs;
	color: $header-color;
	padding: calc(#{$spacing--md} + var(--frame-width, 0px)) var(--frame-width, 0px) $spacing--md var(--frame-width, 0px);
	z-index: map-get($zindex, header);
	// background-color: $header-background-color;
	background-color: transparent;

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		background-color: $color-petrol;
		position: relative;
	}
	// transition: transform .3s $ease-in-out-expo .2s, background-color .3s $ease-in-out-expo .2s, opacity .3s $ease-in-out-expo .2s, margin .3s $ease-in-out-expo .2s;
	transition: transform .3s $ease-in-out-expo .2s, background-color .3s $ease-in-out-expo .2s, margin .3s $ease-in-out-expo .2s;
	will-change: transform, background-color, opacity, margin;

	min-height: 115px;
	margin-bottom: -115px;

	@include mq("md") {
		min-height: 200px;
		margin-bottom: -200px;
	}
}

@media (prefers-reduced-motion: reduce) {
	.h-header {
		transition: none;
	}
}

.h-header {
	opacity: var(--opacity);
}

.h-header.is-solid.is-atop {
	background-color: rgba($header-background-color, 1);
	opacity: 1;
}

.h-header.is-transparent.is-atop {
	background-color: rgba($header-background-color, 0);
	opacity: 1;
}

.h-header.has-headercard {
	margin-bottom: -115px;

	@include mq("md") {
		margin-bottom: -200px;
	}
}

.h-header.has-noheadercard {
	margin-bottom: 0px;
}

.h-header.is-transparent.is-open {
	transition: none;
	background-color: rgba($header-background-color, 1);
	opacity: 1;
}

.h-header__identity {
	// Font size controls the icons size
	font-size: calc(2rem + #{$spacing--sm}); // 3rem;
}

.h-header__navigation {
	text-align: right;
}

.h-header__nav {
	display: none;
	position: absolute;
	width: 100%;
	height: calc(100vh - #{100px});
	top: 100px;
	left: 0;
	z-index: map-get($zindex, header-panel);
	margin: auto;
	
	@include mq("md", max) {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	padding: var(--view-width-radical-px, 3rem) calc(var(--view-width-radical-px, 3rem) + var(--frame-width, 0));

	@include mq("md") {
		padding: 0;
	}

	background-color: $header-panel-color;

	@include mq("md") {
		background-color: transparent;
		height: auto;
		display: block;
		position: static;
	}
}

.h-header__nav-toggle {
	@include mq("md") {
		display: none;
	}
}

.h-header__nav-controller {
	display: none;
	&:checked ~ .h-header__nav {
		display: block;
	}
}

.h-header__icon {
	width: 2em;
	height: 2em;
	display: inline-block;
	position: relative;
}

.h-header__icon-bar {
	display: block;
	width: 100%;
	height: 0px;
	position: absolute;
	background: currentColor;
	border: .8px solid currentColor;
	opacity: 0;

	top: 50%;
	transform: rotate(0) translateY(-50%);

	transition: transform 250ms ease-in, opacity 250ms ease-in;
}

.h-header__icon-bar:first-child {
	margin-top: -.5em;
	opacity: 1;
}

.h-header__icon-bar:last-child {
	margin-top: .5em;
	opacity: 1;
}

.h-header__nav-controller {
	&:checked ~ .h-header__nav-toggle {
		.h-header__icon-bar:first-child {
			opacity: 0;
		}
		.h-header__icon-bar:last-child {
			opacity: 0;
		}
		.h-header__icon-bar:nth-child(2) {
			transform: rotate(45deg) translateY(-50%);
			opacity: 1;
		}
		.h-header__icon-bar:nth-child(3) {
			transform: rotate(-45deg) translateY(-50%);
			opacity: 1;
		}
	}
}