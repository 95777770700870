/* ===========================================
   settings.colors
   ========================================= */

/**
 * High-level settings and variables,
 * that are accessible in whole project
*/

// In the Color declaration section we declare
// all colors with a variable name referring to
// the actual color (e.g. $color-grey).
//
// Then in the Color implementation section we declare
// colors used globally for a specific function,
// where we assign a color from the declaration
// section to the varibale
// (e.g. $color-primary: $color-brand-grass).
//
// Going forward, if a color gets used for a specific
// purpose, it should be declared with that purpose
// in the implementation section.
// (e.g. link-, border-, error color, etc.)
//
// This makes refactoring, theming, design updates
// much easier.

//**************************
// Color declaration
//**************************

// Base colors

$color-black: #000000;
$color-white: #ffffff;

$color-corporate: #e85c54;
$color-petrol: #173448;
$color-slate: #636674;

$color-teal: #2b8d9c;
$color-plum: #9f3f89;
$color-sunflower: #ffa100;

// Slate variants
$color-slate--light: #7e818d;
$color-slate--lighter: #aeb1bb;
$color-whitesmoke: #efefef;

// Base grey colors
$color-grey--10: darken($color-white, 10%);
$color-grey--20: darken($color-white, 20%);
$color-grey--30: darken($color-white, 30%);
$color-grey--40: darken($color-white, 40%);
$color-grey--50: darken($color-white, 50%);
$color-grey--60: darken($color-white, 60%);
$color-grey--70: darken($color-white, 70%);
$color-grey--80: darken($color-white, 80%);
$color-grey--90: darken($color-white, 90%);

// Brand colors
$color-midnight: #0020bf;
$color-midnight-light: #f0f2fb;
$color-neon: #ff5b4c;
$color-neon-light: #ffefed;
$color-rosa: #de73b9;
$color-rosa-light: #fbf4f9;
$color-purple: #5f4292;
$color-purple-light: #f5f1fe;
$color-aqua: #27b4c7;
$color-aqua-light: #eff9fa;
$color-pink: #d98dbf;
$color-earth: #994c33;
$color-plant: #65734b;
$color-octane: #6b9299;
$color-azure: #7accb1;
$color-gold: #e3cf62;
$color-black-alt: #141414;

//**************************
// Color implementation
//**************************

// Body
$body-color: $color-slate;

// Main
$main-background-color: $color-white;

// Header
$header-color: $color-white;
$header-background-color: $color-petrol;
$header-panel-color: $color-petrol;

// Nav
$nav-color--active: $color-white;
$nav-color--corporate: $color-corporate;

// Footer
$footer-color: $color-white;
$footer-background-color: $color-petrol;

// CTA
$cta-color: $color-white;
$cta-background-color: $color-corporate;

// Link
$link-color: $color-corporate;

// Action
$action-color-dark-primary: $color-slate;
$action-color-dark-secondary: $color-white;
$action-color-light-primary: $color-white;
$action-color-light-secondary: $color-corporate;
$action-color-transparent-primary: $color-white;
$action-color-transparent-secondary: $color-slate;

// Card
$card-color-foreground: $color-white;
$card-color-foreground-dimmed: rgba($color-white, 0.5);
$card-color-background: $color-white;
$card-color-background-dark: $color-petrol;
$card-color-accent: $color-corporate;
$card-color-accent-background-dark: $color-white;
$card-color-text: $color-slate--light;
$card-color-text-dark: $color-slate;
$card-color-kicker: rgba($color-petrol, 0.5);

// Section
$section-accent-color: $color-corporate;

// Article
$article-accent-color: $color-corporate;
$article-lead-color: $color-slate--light;
$article-byline-color: $color-slate--lighter;
$article-author-color: $color-slate--lighter;
$article-caption-color: $color-slate--lighter;
$article-cell-color: $color-slate--lighter;

// Cookie
$cookie-color: $color-black;
$cookie-bg-color: $color-grey--10;

// Newsletter popup
$newsletter-popup-background: #491748;
$newsletter-popup-color: $color-white;
$newsletter-popup-kicker: $color-corporate;

/**
* Forms
*/
$form-color: $color-grey--60;
$form-focus-color: $color-slate;
$form-error-color: $color-corporate;
$form-focus-ring-color: $color-sunflower;

$form-filled-background: $color-white;
$form-filled-color: $color-slate;
