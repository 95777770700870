.m-article-media {
	margin-top: $spacing--md;
	margin-bottom: $spacing--md;

	&__caption {
		font-style: italic;
		font-size: $font-size--xxs;
		color: $article-caption-color;
		margin-top: $spacing--xs;
	}
}