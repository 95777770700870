.h-footer {
	font-size: $font-size--xs;
	padding: 7rem #{$spacing--md} 4rem;
	line-height: $line-height--xxl;
	color: $footer-color;
	background-color: $footer-background-color;
	margin-bottom: calc(#{$grid-gutter} * 2);

	// Make the footer have curtain reveal effect
	position: sticky;
	bottom: calc(#{$grid-gutter} * 2);
	// We must apply both z-index and transform for the reveal effect to work in Safari
	z-index: map-get($zindex, terra);
	transform: translateZ(-1px);
}

.h-footer__card {
	margin-bottom: 1.5rem;

	@include mq("md") {
		margin-bottom: 6rem;
	}

	.a-heading {
		text-transform: uppercase;
		margin-bottom: 0.5em;
	}

	.m-card__textbox {
		font-size: $font-size--xs;
		text-align: center;

		@include mq("md") {
			text-align: left;
		}
	}
}

.h-footer__identity {
	// Font size controls the icons size
	font-size: calc(2rem + #{$spacing--sm}); // 3rem;
}
