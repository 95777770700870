/* ===========================================
	atoms.icon
	========================================= */

/**
 *  Atoms – basic building blocks of matter.
 *  Class-based selectors, that define
 *  undecorated, unopinionated design patterns
*/

.a-svg-sprite {
	// https://stackoverflow.com/questions/29993846/why-does-svg-use-xlinkhref-referencing-an-element-with-clip-path-not-wor
	position: relative;
	z-index: map-get($zindex, sub);
	width: 0;
	height: 0;
}