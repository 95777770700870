.m-article-author {
	margin-top: $spacing--xl;
	padding-top: $spacing--xl;
	margin-bottom: $spacing--xl;
	border-top: $border--sm solid $color-whitesmoke;
	font-size: $font-size--sm;
	line-height: $line-height--xxl;
	
	&__title {
		color: $article-author-color;
		text-transform: uppercase;
	}
	
	&__link {
		color: $article-author-color;
	}
	
	&__bio {
		color: $article-author-color;
		margin-top: 1em;
	}
}