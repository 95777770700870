.m-link {
	position: relative;
	width: 100%;
	height: 100%;

	@include mq('md') {
		overflow: hidden;
	}

	.u-grid--columns-33 & {

		@include mq('md') {
			.m-link {
				&__spacer {
					display: block;
					height: 0;
					padding-bottom: 70%;
					position: relative;
				}
			
				&__textbox {
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					position: absolute;
					display: flex;
					justify-content: center;
					flex-direction: column;
					z-index: map-get($zindex, hill);
				}
			}
		}
	}
}