.m-article-header {
	margin-top: $spacing--xl;
	margin-bottom: $spacing--md;
	
	&__kicker {
		font-size: $font-size--xxs;
		font-weight: $font-weight--medium;
		text-transform: uppercase;
		color: $article-accent-color;
		margin-bottom: .5em;
	}

	&__heading {
		margin-bottom: $spacing--xs;
		line-height: $line-height--lg;
	}

	&__lead {
		font-size: $font-size--lg;
		line-height: $line-height--xxl;
		color: $article-lead-color;
		max-width: 85%;
	}
}