$spacings: (
	none: 0,
	xxs: $spacing--xxs,
	xs: $spacing--xs,
	sm: $spacing--sm,
	md: $spacing--md,
	lg: $spacing--lg,
	xl: $spacing--xl,
	xxl: $spacing--xxl
);

@each $name, $value in $spacings {

	.u-padding--#{$name} {
		padding: $value;
	}

	.u-bottom-margin--#{$name} {
		margin-bottom: $value;
	}

	.u-bottom-padding--#{$name} {
		padding-bottom: $value;
	}

	.u-top-margin--#{$name} {
		margin-top: $value;
	}

	.u-top-padding--#{$name} {
		padding-top: $value;
	}

	.u-left-padding--#{$name} {
		padding-left: $value;
	}

	.u-left-margin--#{$name} {
		margin-left: $value;
	}

	.u-right-padding--#{$name} {
		padding-right: $value;
	}

	.u-right-margin--#{$name} {
		margin-right: $value;
	}
};