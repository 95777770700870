.o-clock {
	color: currentColor;
	display: block;
	width: 5em;
	height: 5em;
	position: relative;
	border: .8px solid currentColor;
	border-radius: 50%;
	margin: 1em auto;
}

.o-clock__dozenth {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	list-style: none;
}

.o-clock__dozenth:before {
	width: .8px;
	height: 10%;
	position: absolute;
	left: 50%;
	top: 0;
	background-color: currentColor;
	margin-top: 5%;
	margin-left: -.4px;
	content: " ";
}

.o-clock__dozenth:nth-of-type(1) {
	transform: rotate(30deg);
}

.o-clock__dozenth:nth-of-type(2) {
	transform: rotate(60deg);
}

.o-clock__dozenth:nth-of-type(3) {
	transform: rotate(90deg);
}

.o-clock__dozenth:nth-of-type(4) {
	transform: rotate(120deg);
}

.o-clock__dozenth:nth-of-type(5) {
	transform: rotate(150deg);
}

.o-clock__dozenth:nth-of-type(6) {
	transform: rotate(180deg);
}

.o-clock__dozenth:nth-of-type(7) {
	transform: rotate(210deg);
}

.o-clock__dozenth:nth-of-type(8) {
	transform: rotate(240deg);
}

.o-clock__dozenth:nth-of-type(9) {
	transform: rotate(270deg);
}

.o-clock__dozenth:nth-of-type(10) {
	transform: rotate(300deg);
}

.o-clock__dozenth:nth-of-type(11) {
	transform: rotate(330deg);
}

.o-clock__dozenth:nth-of-type(12) {
	transform: rotate(360deg);
}

.o-clock__minutehand,
.o-clock__hourhand {
	width: .8px;
	position: absolute;
	left: calc(50% - .4px);
	z-index: map-get($zindex, hill);
	background: currentColor;
	transform-origin: 50% 100%;
}

.o-clock__minutehand {
	height: 40%;
	top: 10%;
}

.o-clock__hourhand {
	height: 25%;
	top: 25%;
}