.a-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.a-icon__asset {
	display: block;
	width: 100%;
	height: 100%;
	fill: currentColor;
}