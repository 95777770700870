.pl-grid {
	border: 2px solid #ff69b4;
}

.pl-grid__cell {
	border: 2px solid #b8bcbd;
	background-color: #b2f5b5;
}

.pl-grid__text {
	background-color: white;
}