.h-main {
	background-color: $main-background-color;
	position: relative;
	z-index: map-get($zindex, plain);

	// Make sure the main container covers the footer
	overflow: auto;
	
	.has-noheadercard + & {
		padding-top: 115px;
		margin-top: -115px;
	}

	transition: margin .3s $ease-in-out-expo .2s, padding .3s $ease-in-out-expo .2s;
	will-change: margin, padding;
}