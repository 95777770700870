.u-hidden {
	display: none;
}

.u-hidden--sm {
	@include mq('sm') {
		display: none;
	}
}

.u-hidden--absolute {
	position: absolute;
	left: -99999px;
}

.u-overflow-hidden {
	overflow: hidden;
}