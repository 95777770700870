/* ===========================================
	settings.grid-variables
	========================================= */

/**
 *  High-level settings and variables,
 *  that are accessible in whole project
*/

// Grid based on BlazeUI's grid
// More info: http://blazeui.com/objects/grid/

$grid-gutter: calc(var(--view-width-radical-px, 2em) / 2) !default;

$grid-widths: (
	5: 5 / 100,
	10: 1 / 10,
	15: 15 / 100,
	16: 1 / 6,
	20: 1 / 5,
	25: 1 / 4,
	30: 3 / 10,
	33: 1 / 3,
	35: 35 / 100,
	40: 2 / 5,
	45: 45 / 100,
	50: 1 / 2,
	55: 55 / 100,
	60: 3 / 5,
	65: 65 / 100,
	66: 2 / 3,
	70: 7 / 10,
	75: 3 / 4,
	80: 4 / 5,
	85: 85 / 100,
	90: 9 / 10,
	95: 95 / 100,
	100: 1,

	"1col": 1 / 12,
	"2col": 2 / 12,
	"3col": 3 / 12,
	"4col": 4 / 12,
	"5col": 5 / 12,
	"6col": 6 / 12,
	"7col": 7 / 12,
	"8col": 8 / 12,
	"9col": 9 / 12,
	"10col": 10 / 12,
	"11col": 11 / 12,
	"12col": 1,
) !default;
