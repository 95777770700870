/* ===========================================
  atom.image
  ========================================= */
.a-image {
	width: 100%;

	&__asset {
		width: 100%;
		height: auto;
		display: block;
	}
}

/*
/* Image: Cover
/* -------------------------------------------------- */
.a-image--cover .a-image__asset {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*
/* Image: Maintain 16/9 format
/* -------------------------------------------------- */
.a-image--16-9 {
	position: relative;

	&::after {
		content: '';
		@include aspect-ratio(16, 9);
	}

	> picture,
	> img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}