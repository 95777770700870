/* ===========================================
	 tools.display-mixins
	 ========================================= */

/**
 * High-level mixins and functions,
 * that are accessible in whole project
*/

// Based on BlazeUI
// More info: http://blazeui.com


@mixin visible {
	visibility: visible;
}
	
@mixin invisible {
	visibility: hidden;
}
	
@mixin visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip-path: inset(50%);
}
	
@mixin display-none {
	display: none;
}
	
@mixin display-initial {
	display: initial;
}
	
@mixin display-inline {
	display: inline;
}
	
@mixin display-inline-block {
	display: inline-block;
}
	
@mixin display-block {
	display: block;
}
	
@mixin display-table {
	display: table;
}
	
@mixin display-table-cell {
	display: table-cell;
}
	
@mixin display-flex {
	display: flex;
}
	
@mixin display-inline-flex {
	display: inline-flex;
}