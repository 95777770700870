.a-image-contain {
	&--fallback {
		background-size: contain;

		&.a-image-contain--center {
			background-position: center center;
		}
	}
}

.a-image-contain__asset {
	width: 100%;
	height: 100%;
	object-fit: contain;
}