@mixin font-face {
	@font-face {
		font-family: 'Gotham';
		font-weight: 100;
		font-style: normal;
		src: url('/dist/fonts/gotham-thin-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-thin-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 100;
		font-style: italic;
		src: url('/dist/fonts/gotham-thinitalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-thinitalic-webfont.woff') format('woff');
		font-display: swap;
	}



	@font-face {
		font-family: 'Gotham';
		font-weight: 200;
		font-style: normal;
		src: url('/dist/fonts/gotham-xlight-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-xlight-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 200;
		font-style: italic;
		src: url('/dist/fonts/gotham-xlightitalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-xlightitalic-webfont.woff') format('woff');
		font-display: swap;
	}



	@font-face {
		font-family: 'Gotham';
		font-weight: 300;
		font-style: normal;
		src: url('/dist/fonts/gotham-light-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-light-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 300;
		font-style: italic;
		src: url('/dist/fonts/gotham-lightitalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-lightitalic-webfont.woff') format('woff');
		font-display: swap;
	}



	@font-face {
		font-family: 'Gotham';
		font-weight: 400;
		font-style: normal;
		src: url('/dist/fonts/gotham-book-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-book-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 400;
		font-style: italic;
		src: url('/dist/fonts/gotham-bookitalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-bookitalic-webfont.woff') format('woff');
		font-display: swap;
	}



	@font-face {
		font-family: 'Gotham';
		font-weight: 500;
		font-style: normal;
		src: url('/dist/fonts/gotham-medium-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-medium-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 500;
		font-style: italic;
		src: url('/dist/fonts/gotham-mediumitalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-mediumitalic-webfont.woff') format('woff');
		font-display: swap;
	}



	@font-face {
		font-family: 'Gotham';
		font-weight: 700;
		font-style: normal;
		src: url('/dist/fonts/gotham-bold-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-bold-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 700;
		font-style: italic;
		src: url('/dist/fonts/gotham-bolditalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-bolditalic-webfont.woff') format('woff');
		font-display: swap;
	}



	@font-face {
		font-family: 'Gotham';
		font-weight: 800;
		font-style: normal;
		src: url('/dist/fonts/gotham-black-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-black-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 800;
		font-style: italic;
		src: url('/dist/fonts/gotham-blackitalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-blackitalic-webfont.woff') format('woff');
		font-display: swap;
	}



	@font-face {
		font-family: 'Gotham';
		font-weight: 900;
		font-style: normal;
		src: url('/dist/fonts/gotham-ultra-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-ultra-webfont.woff') format('woff');
		font-display: swap;
	}

	@font-face {
		font-family: 'Gotham';
		font-weight: 900;
		font-style: italic;
		src: url('/dist/fonts/gotham-ultraitalic-webfont.woff2') format('woff2'),
			url('/dist/fonts/gotham-ultraitalic-webfont.woff') format('woff');
		font-display: swap;
	}
}